<template>
    <v-container class="fill-height fluid>">
        <v-row justify="center" align="center">

            <div class="d-flex flex-wrap justify-center">
                <div v-for="card in cards">
                    <v-card class="d-flex flex-column ma-10 rounded-shaped" elevation=10 width="460" height="780"
                        v-if="card.has_permissions()">
                        <a :href="card.link">
                            <v-img cover class="" height="500px" :src="card.image"></v-img>
                        </a>
                        <v-card-title>{{ card.title }}</v-card-title>
                        <v-card-text class="pt-3">
                            <div>
                                {{ card.description }}
                            </div>
                            <div class="pt-2 font-weight-bold" v-if="card.internal_only">
                                {{ $t('home__internal_disclaimer') }}
                            </div>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <div class="" v-if="card.actions.length>0">
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn v-for="act in card.actions" color="light-blue darken-2" :prepend-icon="act.icon"
                                    variant="text" :to="act.link_type == 'internal' ? act.path : ''"
                                    :href="act.link_type == 'external' ? act.path : ''" class="mx-2"
                                    :target="act.link_type == 'external' ? '_blank' : ''">
                                    {{ act.title }}
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </div>
            </div>
        </v-row>
    </v-container>
</template>
  
  
<script setup>
import { onMounted, ref, computed } from "vue";
import { useS3 } from "@/composables/useS3";
import { useUserStore } from "@/stores/user";
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
const s3 = useS3()
const user = useUserStore()


const cards = computed(()=>{
 return [
    {
        title: t('home__systemselektor_title'),
        description: t('home__systemselektor_description'),
        image: "https://cdn.sick.com/media/895/6/46/446/IM0088446.png",
        actions: [
            { title: t('home__systemselektor_my_systems'), icon: "mdi-application", path: "systemselektor", link_type: "internal" },
            { title: t('home__systemselektor_new_system'), icon: "mdi-plus", path: "systemselektor/request", link_type: "internal" },
        ],
        internal_only: false,
        has_permissions: () => { return true },
    },
    {
        title: t('home__tco_title'),
        description: t('home__tco_description'),
        image: s3.get(s3.Bucket.PUBLIC, 'tco.png'),
        actions: [
            // { title: "Kalkulation beginnen", icon: "mdi-calculator", path: "tco", link_type: "internal" },
        ],
        internal_only: false,
        has_permissions: () => { return true }
    },
    {
        title: t('home__stellar_light_title'),
        description: t('home__stellar_light_description'),
        image: s3.get(s3.Bucket.PUBLIC, 'lector_wallpaper.jpg'),
        actions: [
            { title: t('home__stellar_light_open'), icon: "mdi-link", path: "https://stellar.place.sickcn.net/home", link_type: "external" },
        ],
        internal_only: true,
        has_permissions: () => { return true }
    }
]
})


onMounted(() => {

})
</script>

