import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { useSystemRequestStore } from "@/stores/system_request";

export const useAutosaveStore = defineStore(
  "autosave",
  () => {
    const systemRequestStore = useSystemRequestStore();
    const { request } = storeToRefs(systemRequestStore);
    const applicationData = ref({});

    // watch(
    //   () => request,
    //   () => {
    //     save();
    //   },
    //   { deep: true }
    // );

    function save() {
      applicationData.value = { ...request.value };
      // console.log(applicationData.value);
    }

    function load() {
      if (!applicationData.value) {
        return;
      }
      request.value = {
        ...request.value,
        ...applicationData.value
      };
    }

    return {
      applicationData,
      save,
      load,
    };
  },
  {
    persist: true,
  }
);
