<template>
  <v-footer app max-height="28px" class="grey--text" style="font-size: x-small" color="transparent">
    <v-row>
      <v-col cols="3" class="text-left">
        {{ app_version }}
      </v-col>
      <v-col class="text-center" cols="6">
        <v-btn variant="text" color="grey" size="x-small" href="https://www.sick.com/de/de/impressum/w/imprint"
          target="_blank">Impressum</v-btn>
        |
        <v-btn variant="text" color="grey" size="x-small" href="https://www.sick.com/de/de/allgemeine-geschaeftsbedingungen/w/tac"
          target="_blank">AGB</v-btn>
        |
        <v-btn variant="text" color="grey" size="x-small" href="https://www.sick.com/de/de/nutzungsbedingungen/w/terms-of-use"
          target="_blank">Nutzungsbedingungen</v-btn>
        |
        <v-btn variant="text" color="grey" size="x-small" href="https://www.sick.com/de/de/sick-datenschutzerklaerung/w/dataprotection"
          target="_blank">Datenschutzerklärung</v-btn>
      </v-col>

      <v-col cols="3" class="text-right">
        Copyright © {{ new Date().getFullYear() }} Sick Vertriebs GmbH. All Rights reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup>

const app_version = import.meta.env.VITE_APP_VERSION;

</script>

<style scoped></style>
