<template>
  <v-container>
    <v-card :title="$t('usermanagement__title')">
      <v-row class="px-5" align="center" justify="start">
        <v-col xl="3" lg="4" xs="12">
          <v-text-field
            clearable
            variant="underlined"
            prepend-inner-icon="mdi-magnify"
            :label="$t('search')"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>

        <v-col xl="3" lg="4" xs="12">
          <v-select
            multiple
            chips
            closable-chips
            variant="underlined"
            :label="t('usermanagement__group')"
            :items="all_groups"
            v-model="selectedGroups"
            prepend-inner-icon="mdi-account-group"
          ></v-select>
        </v-col>

        <v-col xl="3" lg="4" xs="12">
          <v-switch
            :label="t('usermanagement__nogroup')"
            v-model="unassignedUsers"
            hide-details
            color="primary"
          ></v-switch>
        </v-col>
      </v-row>
      <!-- @update:options="update_options($event)" -->
      <!-- :items-length="totalItems" -->
      <!-- :search="searchTerm" -->

      <v-card-text id="scrollable_content_and_footer">
        <v-data-table
          :headers="header"
          :items="filteredItems"
          :loading="loading"
          :search="searchTerm"
          :filter-keys="['groups', 'username']"
        >
          <!-- <template v-slot:top>
          <v-text-field
            class="mx-2"
            variant="underlined"
            prepend-inner-icon="mdi-magnify"
            :label="$t('search')"
            v-model="searchTerm"
          ></v-text-field>
        </template> -->
          <template v-slot:loading>
            <v-skeleton-loader type="table-row-divider@6"></v-skeleton-loader>
          </template>

          <template v-slot:item.groups="{ item }">
            <v-chip-group
              column
              multiple
              v-model="item.groups"
              @update:model-value="manageUserGroup(item.id, item.groups)"
            >
              <v-chip
                :value="group"
                color="success"
                v-for="group in all_groups"
                >{{ group }}</v-chip
              >
            </v-chip-group>
          </template>

          <template v-slot:item.is_superuser="{ item }">
            <v-checkbox
              disabled
              center-affix
              hide-details
              density="compact"
              v-model="item.is_superuser"
            ></v-checkbox>
          </template>

          <template v-slot:item.is_staff="{ item }">
            <v-checkbox
              disabled
              center-affix
              hide-details
              density="compact"
              v-model="item.is_staff"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import { useAPI } from "@/composables/useAPI";
import { ref, watch, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/user";

const loading = ref(true);
const user = useUserStore();
const { t } = useI18n();
const api = useAPI();
const unassignedUsers = ref(false);

const header = computed(() => [
  { title: t("usermanagement__username"), key: "username", sortable: true },
  { title: t("usermanagement__group"), key: "groups", sortable: true },
  {
    title: t("usermanagement__superuser"),
    key: "is_superuser",
    sortable: true,
  },
  { title: t("usermanagement__staffuser"), key: "is_staff", sortable: true },
]);

const all_groups = ref([]);
const selectedGroups = ref([]);
var items = ref([]);
var searchTerm = ref("");

const filteredItems = computed(() => {
  if (unassignedUsers.value) {
    return items.value.filter((item) => item.groups.length === 0);
  }
  const groups = items.value.filter((item) => {
    return (
      // item.username.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      selectedGroups.value.every((group) => item.groups.includes(group))
    );
  });
  return groups;
});

// var totalItems = ref(0);
// var options = ref({});

// function update_options(e) {
//   if (e.itemsPerPage < 0) {
//     e.itemsPerPage = totalItems.value;
//   }
//   options.value = e;
// }

// watch(options, (_) => {
//   getDataFromApi();
// });

function manageUserGroup(item_id, groups) {
  var permission_set = {};
  all_groups.value.forEach((group) => {
    permission_set[group] = groups.includes(group);
  });
  if (user.id === item_id) {
    user.groups = groups;
  }
  api.userAPI.userPermissions(item_id, permission_set);
}

function getDataFromApi() {
  // const { _, page, itemsPerPage, search } = options.value;
  //search, page, itemsPerPage)
  loading.value = true;
  api.userAPI
    .userList(undefined, 1, 99999)
    .then((res) => {
      items.value = res.data.results;
      // totalItems.value = res.data.count;
      loading.value = false;
    })
    .catch((err) => {
      console.error(err);
    });
}

function getAllGroups() {
  const search_term = undefined;
  api.groupAPI.groupList(search_term, 1, -1).then((res) => {
    all_groups.value = res.data.results.map((x) => x.name);
  });
}

onMounted(() => {
  getDataFromApi();
  getAllGroups();
});
</script>

<style scoped>
#scrollable_content_and_footer {
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
  max-height: calc(100vh - 260px);
}
</style>
