import Home from "@/pages/Home";
import Login from "@/pages/Login";
import SystemRequest from "@/pages/SystemRequest";
import Systems from "@/pages/Systems";
import Usermanagement from "@/pages/Usermanagement";
import UserGroupManagement from "@/pages/UserGroupManagement";
import SystemManagement from "@/pages/SystemManagement";
import Settings from "@/pages/Settings";
import NoPermissions from "@/pages/NoPermissions";

const routes = [
  {
    path: "/",
    component: SystemRequest,
    meta: { requiresAuth: true },
  },
  {
    path: "/home",
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: "/login/:pathMatch(.*)*",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/systemselektor",
    component: Systems,
    meta: { requiresAuth: true },
  },
  {
    path: "/systemselektor/request/:params+",
    props: (route) => ({
      ...route.params,
    }),
    component: SystemRequest,
    meta: { requiresAuth: true },
  },
  {
    name: "project",
    path: "/systemselektor/request/:projectID(\\d+)",
    props: true,
    component: SystemRequest,
    meta: { requiresAuth: true },
  },
  {
    path: "/systemselektor/request",
    component: SystemRequest,
    meta: { requiresAuth: true },
  },
  {
    path: "/administration/usermanagement",
    component: Usermanagement,
    meta: { requiresAuth: true },
  },
  {
    path: "/administration/system_management",
    component: SystemManagement,
    meta: { requiresAuth: true },
  },
  {
    path: "/administration/usergroupmanagement",
    component: UserGroupManagement,
    meta: { requiresAuth: true },
  },
  {
    name: "settings",
    path: "/settings/settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: "/no_permissions",
    component: NoPermissions,
    meta: { requiresAuth: true },
  },
];

export default routes;
