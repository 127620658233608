import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useSystemRequestStore } from "@/stores/system_request";
import { storeToRefs } from "pinia";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  AlignmentType,
  WidthType,
  BorderStyle,
  Header,
  Footer,
  ImageRun,
} from "docx";
import { useSettingsStore } from "@/stores/settings";
import { saveAs } from "file-saver";

function useReportGenerator(t, te) {
  const settings = useSettingsStore();
  const system_request = useSystemRequestStore();
  const { request, survey, selected } = storeToRefs(system_request);

  function yyyymmdd(dateIn) {
    var yyyy = dateIn.getFullYear();
    var mm = dateIn.getMonth() + 1;
    var dd = dateIn.getDate();
    return String(10000 * yyyy + 100 * mm + dd);
  }

  function getQuestionLabel(section, question) {
    if (te("system_request_" + section.label + "_" + question.label)) {
      return t("system_request_" + section.label + "_" + question.label);
    }
    return question[settings.locale];
  }

  function getSectionLabel(section) {
    if (te("system_request_" + section.label)) {
      return t("system_request_" + section.label);
    }
    return section[settings.locale];
  }

  function formatDropdownValue(section, question, value) {
    console.log(
      question.label,
      "VALUE: ",
      value,
      "OPTS: ",
      question.options.map((e) => e.label)
    );
    let labelPrefix =
      "system_request_" + section.label + "_" + question.label + "_";
    if (te(labelPrefix + value)) {
      return t(labelPrefix + value);
    }
    const option = question.options.find((e) => e.label == value);
    if (!option) {
      console.log("ERR", question.label, question.options, value)
      return "";
    }
    return option[settings.locale];
  }

  function formatMultiSelectValue(section, question, value) {
    let labelPrefix =
      "system_request_" + section.label + "_" + question.label + "_";
    return value
      .map((v) =>
        te(labelPrefix + v.label)
          ? t(labelPrefix + v.label)
          : question.options.find((e) => e.label == v)[settings.locale]
      )
      .join(", ");
  }

  function formatSliderValue(question, value) {
    return parseFloat(value) + " " + question.unit;
  }

  function formatRangeSliderValue(question, value) {
    return (
      parseFloat(value[0]) +
      " " +
      question.unit +
      " - " +
      parseFloat(value[1]) +
      " " +
      question.unit
    );
  }

  function formatTextInputValue(value) {
    return value;
  }

  function formatBinaryInputValue(value) {
    if (value) {
      return t("system_detail__yes");
    }
    return t("system_detail__no");
  }

  function formatValue(section, question, specification) {
    const value = question.data_field
      ? specification[question.data_field]
      : specification[section.label + "__" + question.label];
    // if (!value) {
    //   return "";
    // }
    switch (question.type) {
      case "dropdown":
        return formatDropdownValue(section, question, value);
      case "multi-select":
        return formatMultiSelectValue(section, question, value);
      case "slider":
        return formatSliderValue(question, value);
      case "range-slider":
        return formatRangeSliderValue(question, value);
      case "text-input":
        return formatTextInputValue(value);
      case "binary-input":
        return formatBinaryInputValue(value);
      default:
        return "";
    }
  }

  function aredisjoint(set1, set2) {
    for (let i = 0; i < set1.length; i++) {
      for (let j = 0; j < set2.length; j++) {
        if (set1[i] == set2[j]) return false;
      }
    }
    return true;
  }

  function includeInReport(element, specification) {
    if (element.includeIf == null) {
      return true;
    }

    for (let c in element.includeIf) {
      let condition = element.includeIf[c];
      let selection = specification[condition.field];
      if (selection == null) {
        return false;
      }

      if (Array.isArray(selection)) {
        if (aredisjoint(condition.values, selection)) {
          return false;
        }
      } else {
        if (!condition.values.includes(selection)) {
          return false;
        }
      }
    }
    return true;
  }

  function getApplicationData(specification, app_spec) {
    let data = {};
    const sections = app_spec.sections.filter((s) =>
      includeInReport(s, specification)
    );
    for (const section of sections) {
      let sectionData = [];
      for (const question of section.questions) {
        if (includeInReport(question, specification)) {
          sectionData.push([
            getQuestionLabel(section, question),
            formatValue(section, question, specification),
          ]);
        }
      }
      data[getSectionLabel(section)] = sectionData;
    }
    return data;
  }

  function getPDFSolutionContent(solution) {
    function tryAddProp(key, name) {
      if (solution.hasOwnProperty(key) && solution[key] != "None") {
        data.push([name, solution[key]]);
      }
    }

    var content = {};
    const basics = [
      [t("pdf_generator__name"), solution.name],
      [t("pdf_generator__system_type"), solution.type],
      [t("pdf_generator__typ_code"), solution.type_code],
      [t("pdf_generator__material_number"), solution.material_number],
      [
        t("pdf_generator__price"),
        (
          parseInt(solution.costs.system) + parseInt(solution.costs.service)
        ).toLocaleString() + " €",
      ],
    ];
    content[t("pdf_generator__system_solution")] = basics;

    var data = [];
    tryAddProp("system_type", t("pdf_generator__reading"));
    tryAddProp("ops_type", t("pdf_generator__ops_type"));
    tryAddProp("scale_type", t("pdf_generator__scale_type"));

    if (
      solution.hasOwnProperty("reading_count") &&
      solution.hasOwnProperty("clv_type")
    ) {
      data.push([
        t("pdf_generator__scanner"),
        solution.reading_count + " x " + solution.clv_type,
      ]);
    }
    if (
      solution.hasOwnProperty("reading_count") &&
      solution.hasOwnProperty("camera_type")
    ) {
      data.push([
        t("pdf_generator__camera"),
        solution.reading_count + " x " + solution.camera_type,
      ]);
    }
    if (
      solution.hasOwnProperty("reading_count") &&
      solution.hasOwnProperty("lector_type") &&
      solution.hasOwnProperty("lector_system_type")
    ) {
      data.push([
        t("pdf_generator__lector"),
        solution.reading_count + " x " + solution.lector_type,
        +solution.lector_system_type,
      ]);
    }
    if (
      solution.hasOwnProperty("reading_count") &&
      solution.hasOwnProperty("vms_type")
    ) {
      data.push([
        t("pdf_generator__vms"),
        solution.reading_count + " x " + solution.vms_type,
      ]);
    }

    tryAddProp("vms_head", t("pdf_generator__vms_head"));
    tryAddProp("antenna_count", t("pdf_generator__antenna_count"));
    tryAddProp("rf_system_type", t("pdf_generator__rf_system_type"));
    tryAddProp("rfid_device", t("pdf_generator__rfid_device"));
    tryAddProp("controller_type", t("pdf_generator__controller_type"));
    tryAddProp("focus_type", t("pdf_generator__focus_type"));
    tryAddProp("max_conveyor_width", t("pdf_generator__max_covneyor_width"));
    tryAddProp(
      "maximal_object_height",
      t("pdf_generator__maximal_object_height")
    );

    if (solution.hasOwnProperty("calibration_value")) {
      data.push([
        t("pdf_generator__calibration"),
        solution.calibration_value + "g",
      ]);
    }
    if (
      solution.hasOwnProperty("min_object_weight") &&
      solution.hasOwnProperty("max_object_weight")
    ) {
      data.push([
        t("pdf_generator__supported_weight"),
        solution.min_object_weight + "g - " + solution.max_object_weight + "kg",
      ]);
    }
    if (
      solution.hasOwnProperty("max_object_length") &&
      solution.hasOwnProperty("max_object_width")
    ) {
      data.push([
        t("pdf_generator__max_package_size"),
        solution.max_object_length + "mm x " + solution.max_object_width + "mm",
      ]);
    }
    tryAddProp("additional_conveyors", t("pdf_generator__additionl_conveyors"));
    if (solution.hasOwnProperty("throughput")) {
      data.push([
        t("pdf_generator__max_pitch"),
        solution.max_object_pitch + " mm",
      ]);
    }
    if (solution.hasOwnProperty("throughput")) {
      data.push([t("pdf_generator__throughput"), solution.throughput + " p/h"]);
    }
    if (solution.hasOwnProperty("crossbeam")) {
      data.push([
        t("pdf_generator__crossbeam"),
        solution.crossbeam == true ? "Ja" : "Nein",
      ]);
    }
    if (solution.hasOwnProperty("lft")) {
      data.push([
        t("pdf_generator__lft"),
        solution.lft == true ? "Ja" : "Nein",
      ]);
    }
    if (
      solution.hasOwnProperty("speed") &&
      solution.speed.min != null &&
      solution.speed.max != null
    ) {
      data.push([
        t("pdf_generator__supported_speed"),
        Number(solution.speed.min).toFixed(2) +
          " - " +
          Number(solution.speed.max).toFixed(2) +
          "m/s",
      ]);
    }
    if (
      solution.hasOwnProperty("sizes") &&
      solution.sizes.l.min != null &&
      solution.sizes.b.min &&
      solution.sizes.h.min != null
    ) {
      data.push([
        t("pdf_generator__min_sizes"),
        solution.sizes.l.min +
          "mm x " +
          solution.sizes.b.min +
          "mm x " +
          solution.sizes.h.min +
          "mm",
      ]);
    }
    if (
      solution.hasOwnProperty("sizes") &&
      solution.sizes.l.max != null &&
      solution.sizes.b.max &&
      solution.sizes.h.max != null
    ) {
      data.push([
        t("pdf_generator__max_sizes"),
        solution.sizes.l.max +
          "mm x " +
          solution.sizes.b.max +
          "mm x " +
          solution.sizes.h.max +
          "mm",
      ]);
    }
    if (
      solution.hasOwnProperty("measurements") &&
      solution.measurements.l != null &&
      solution.measurements.w &&
      solution.measurements.h != null
    ) {
      data.push([
        t("pdf_generator__measurements"),
        solution.measurements.l +
          "mm x " +
          solution.measurements.w +
          "mm x " +
          solution.measurements.h +
          "mm",
      ]);
    }
    if (solution.measurements.weight != null) {
      data.push([
        t("pdf_generator__weight"),
        solution.measurements.weight + " kg",
      ]);
    }
    content[t("pdf_generator__details")] = data;

    var notes = [];
    if (solution.notes != null && solution.notes != "") {
      notes = solution.notes.split(/\r?\n/);
    }
    notes = notes.concat(solution.guide.map((x) => x.description));
    notes = notes.map((note) => {
      return [note];
    });

    if (notes.length > 0) {
      content[t("pdf_generator__remarks")] = notes;
    }

    return content;
  }

  function downloadPDFReport(s3_url, zip, download_filename) {
    const settings = useSettingsStore();
    const specification = request.value;
    const app_spec = survey.value;
    const solution = selected.value;
    const doc = new jsPDF();
    const date = new Date();
    doc.setCreationDate(date);
    const logo = s3_url + "sick.png";
    var imgLogo = new Image();
    imgLogo.src = logo;
    var rendered_pages = [];
    var p = 20;
    for (let [table_header, table_content] of Object.entries(
      getApplicationData(specification, app_spec, t)
    )) {
      doc.autoTable({
        didDrawPage: (h_data) => {
          if (!rendered_pages.includes(h_data.table.startPageNumber)) {
            doc.addImage(imgLogo, "PNG", 10, 5, 20, 6);
            doc.setFontSize(9).setFont("times", "normal");
            doc.text(10, 285, t("pdf_generator__legal_1"));
            doc.text(10, 288, t("pdf_generator__legal_2"));
            if (solution.customer_specific) {
              doc.text(10, 291, t("pdf_generator__customer_specific"));
            }
            rendered_pages.push(h_data.table.startPageNumber);
          }
        },
        head: [
          [
            {
              content: table_header,
              colSpan: 2,
            },
          ],
        ],
        body: table_content,
        styles: {
          overflow: "ellipsize",
        },
        columnStyles: {
          0: {
            halign: "left",
            cellWidth: 80,
          },
        },
        startY: p,
      });
      p = doc.lastAutoTable.finalY + 20;
    }
    doc.addPage("a4");
    var p = 20;
    rendered_pages = [];

    for (let [table_header, table_content] of Object.entries(
      getPDFSolutionContent(solution)
    )) {
      // remove array in nested array - array which includes "Price"
      for (let item of table_content) {
        if (
          item[0] == "Price" &&
          settings.show_system_price_in_report == false
        ) {
          let index = table_content.findIndex(
            (innerAr) => JSON.stringify(innerAr) === JSON.stringify(item)
          );
          if (index >= 0) {
            table_content.splice(index, 1);
          }
        }
      }
      doc.autoTable({
        didDrawPage: (h_data) => {
          if (!rendered_pages.includes(h_data.table.startPageNumber)) {
            doc.setFontSize(9).setFont("times");
            doc.addImage(imgLogo, "PNG", 10, 5, 20, 6);
            doc.text(10, 285, t("pdf_generator__legal_1"));
            doc.text(10, 288, t("pdf_generator__legal_2"));
            if (solution.customer_specific) {
              doc.text(10, 291, t("pdf_generator__customer_specific"));
            }
            rendered_pages.push(h_data.table.startPageNumber);
          }
        },
        head: [
          [
            {
              content: table_header,
              colSpan: table_header == t("pdf_generator__remarks") ? 1 : 2,
            },
          ],
        ],
        body: table_content,
        styles: {
          overflow: "linebreak",
        },
        columnStyles: {
          0: {
            halign: "left",
            cellWidth: table_header == t("pdf_generator__remarks") ? 180 : 80,
          },
        },
        startY: p,
      });
      p = doc.lastAutoTable.finalY + 20;
    }
    if (zip === true) {
      const pdfArrayBuffer = doc.output("arraybuffer");
      return pdfArrayBuffer;
    } else {
      doc.save(download_filename);
    }
  }
  async function getImageBufferFromURL(url) {
    try {
      const response = await fetch(url, { method: "GET" });
      const buffer = await response.arrayBuffer();
      return buffer;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  }

  async function downloadWordReport(s3_url, zip, download_filename) {
    const settings = useSettingsStore();
    const specification = request.value;
    const app_spec = survey.value;
    const solution = selected.value;
    const logo = s3_url + "sick.png";

    const createStyledText = (text, font, size, bold, italic, color) => {
      return new TextRun({
        text: text,
        font: font,
        size: size,
        bold: bold,
        italics: italic,
        color: color,
      });
    };

    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: getImageBufferFromURL(logo), // Image data in buffer form
              transformation: {
                width: 150, // Adjust the width as needed
                height: 50, // Adjust the height as needed
              },
            }),
          ],
        }),
      ],
    });

    const footer = new Footer({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "The above information is based on the information available to us and is merely an assessment of a possible implementation. We do not guarantee the suitability and functionality of the proposed solution in the specific individual case.",
              italics: true,
            }),
          ],
        }),
      ],
    });

    const childrenList = [];

    for (let spec of [
      getApplicationData(specification, app_spec, t),
      getPDFSolutionContent(solution),
    ]) {
      for (let [table_header, table_content] of Object.entries(spec)) {
        const bodyTable = [];
        childrenList.push(new Paragraph({ spacing: { after: 400 } }));
        const headerTable = new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    createStyledText(
                      table_header,
                      "Arial",
                      24,
                      true,
                      false,
                      "FFFFFF"
                    ),
                  ],
                }),
              ],
              borders: {
                top: { style: BorderStyle.SINGLE, size: 3, color: "000000" },
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 3,
                  color: "000000",
                },
                left: { style: BorderStyle.SINGLE, size: 3, color: "000000" },
                right: {
                  style: BorderStyle.SINGLE,
                  size: 3,
                  color: "000000",
                },
              },
              shading: {
                fill: "4F81BD",
              },
              verticalAlign: "center",
            }),
          ],
        });

        for (let item of table_content) {
          if (
            item[0] == "Price" &&
            settings.show_system_price_in_report == false
          ) {
            continue;
          }
          if (item.length < 2) {
            item[1] = "";
          }

          bodyTable.push(
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 4000,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        createStyledText(
                          item[0],
                          "Arial",
                          24,
                          false,
                          false,
                          "000000"
                        ),
                      ],
                    }),
                  ],
                  borders: {
                    top: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                    bottom: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                    left: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                    right: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                  },
                  verticalAlign: "center",
                }),

                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        createStyledText(
                          typeof item[1] === "object"
                            ? item[1].join(", ")
                            : item[1].toLocaleString(),
                          "Arial",
                          24,
                          false,
                          false,
                          "000000"
                        ),
                      ],
                    }),
                  ],
                  borders: {
                    top: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                    bottom: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                    left: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                    right: {
                      style: BorderStyle.SINGLE,
                      size: 1,
                      color: "000000",
                    },
                  },
                  verticalAlign: "center",
                }),
              ],
            })
          );
        }
        const table = new Table({
          rows: [headerTable, ...bodyTable],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          alignment: AlignmentType.CENTER,
        });

        childrenList.push(table);
      }
    }
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 720, // 0.5 inches = 720 twentieths of a point
                right: 720,
                bottom: 1500,
                left: 720,
                // headers: 899, // Margin for the header
              },
            },
          },
          headers: { default: header },
          footers: { default: footer },
          children: childrenList,
        },
      ],
    });

    const blob = await Packer.toBlob(doc);

    if (zip === true) {
      return blob;
    } else {
      saveAs(blob, download_filename);
    }
  }

  function downloadApplicationSpecCSV() {
    const data_to_export = getApplicationData(request.value, survey.value, t);
    const csvRows = [];
    const headers = ["Section", "Field", "Value"];
    csvRows.push("SEP=,");
    csvRows.push(headers.join(","));

    for (const key in data_to_export) {
      const row = data_to_export[key];
      for (const item of row) {
        const values = [key, item[0], '"' + item[1] + '"'];
        csvRows.push(values.join(","));
      }
    }
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const download_filename = "application_spec.csv";
    saveAs(blob, download_filename);
  }

  return {
    downloadPDFReport,
    yyyymmdd,
    downloadWordReport,
    getApplicationData,
    downloadApplicationSpecCSV,
  };
}

export default useReportGenerator;
