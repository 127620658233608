<template>
  <v-container>
    <v-card
      :title="$t('user_group_management__title')"
      id="scrollable_content_and_footer"
    >
      <v-data-table-server
        :search="searchTerm"
        :headers="header"
        :items="items"
        :items-length="totalItems"
        :items-per-page="20"
        @update:options="update_options($event)"
      >
        <template v-slot:top>
          <v-text-field
            class="px-4"
            variant="underlined"
            prepend-inner-icon="mdi-magnify"
            :label="$t('search')"
            v-model="searchTerm"
            @change=""
          ></v-text-field>
        </template>

        <template v-slot:item.permissions="{ item }">
          <v-chip-group
            column
            multiple
            v-model="item.permissions"
            @update:model-value="
              updateGroupPermission(item.id, item.permissions)
            "
          >
            <v-chip
              :value="perm.codename"
              filter
              color="success"
              v-for="perm in all_group_permissions"
              >{{ perm.name }}</v-chip
            >
          </v-chip-group>
        </template>

        <template v-slot:item.profile="{ item }">
          <v-text-field
            max-width="75"
            v-if="item.profile"
            v-model="item.profile.margin_factor"
            hide-details
            variant="outlined"
            density="compact"
            type="number"
            hide-spin-buttons
          ></v-text-field>
        </template>
      </v-data-table-server>
    </v-card>
  </v-container>
</template>

<script setup>
import { useAPI } from "@/composables/useAPI";
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const api = useAPI();

const header = [
  { title: t("usergroupmanagement__name"), key: "name", sortable: false },
  {
    title: t("usergroupmanagement__permissions"),
    key: "permissions",
    sortable: false,
  },
  // {
  //   title: t("usergroupmanagement__margin_factor"),
  //   key: "profile",
  //   sortable: false,
  // },
];

const all_group_permissions = ref([]);
var totalItems = ref(0);
var items = ref([]);
var options = ref({});
var searchTerm = ref("");

function update_options(e) {
  if (e.itemsPerPage < 0) {
    e.itemsPerPage = totalItems.value;
  }
  options.value = e;
}

watch(options, (_) => {
  getDataFromApi();
});

function updateGroupPermission(group_id, permissions) {
  api.groupAPI.groupSetPermissions(group_id, permissions);
}

function getDataFromApi() {
  const { _, page, itemsPerPage, search } = options.value;
  api.groupAPI
    .groupList(search, page, itemsPerPage)
    .then((res) => {
      items.value = res.data.results;
      items.value.forEach((x) => {
        x.permissions = x.permissions.map((x) => x.codename);
      });
      totalItems.value = res.data.count;
    })
    .catch((err) => {
      console.error(err);
    });
}

function getAllGroupPermissions() {
  const search_term = undefined;
  api.groupAPI.groupPermissions(search_term).then((res) => {
    all_group_permissions.value = res.data;
  });
}

onMounted(() => {
  getAllGroupPermissions();
});
</script>
<style scoped>
#scrollable_content_and_footer {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 124px);
}
</style>
