<template>
  <v-list v-model:selected="open" mandatory>
    <v-row no-gutters class="pl-3 pb-2 pt-1">
      <v-col>
        <v-label color="primary">{{ props.title }}</v-label>
      </v-col>
      <!-- <v-col style="display: flex; align-items: center; justify-content: right">
        <v-icon @click="" color="secondary">mdi-chevron-left</v-icon>
      </v-col> -->
    </v-row>
    <v-list-item
      slim
      v-for="(item, i) in props.items"
      :title="item"
      :value="i + 1"
      class="pl-6"
      v-tooltip="tooltips ? tooltips[i] : {disabled: true}"
    >
      <template #prepend="props">
        <v-badge
          :content="i + 1"
          :color="props.isSelected ? 'primary' : 'grey'"
        ></v-badge>
      </template>

      <template #title="props">
        <v-list-item-title v-text="item" class="wrap-text"></v-list-item-title>
      </template>
    </v-list-item>
  </v-list>
</template>

<script setup>
import { ref, watch, computed } from "vue";

const model = defineModel();
const props = defineProps(["title", "items", "tooltips"]);

const open = ref([1]);

const tooltips = computed(() => {
  if (!props.tooltips) return [];
  return props.tooltips.map((tooltip) => {
    return {
      text: tooltip,
      disabled: tooltip === "",
    };
  });
});

watch(open, (val) => {
  model.value = val[0];
});

watch(model, (val) => {
  open.value = [val];
});
</script>

<style scoped>
.wrap-text {
  /* white-space: normal; */
}
.list-heading {
  /* background-color: #f5f5f5; */
  border-bottom: 1px solid #ddd;
  padding-left: 0px;
  margin-left: 0px;
  color: grey;
}
</style>
