<template>
  <ProjectActionPrompt ref="project_prompt" />
  <v-card id="application" :max-height="height - 120">
    <v-toolbar density="compact">
      <v-toolbar-title>
        {{ $t("application_specification") }}
      </v-toolbar-title>
      <BaseToolbarMenu :items="toolbarMenuActions" />
    </v-toolbar>

    <v-row dense no-gutters justify="space-between">
      <v-col xs="4" sm="4" md="4" lg="4" xl="4" xxl="4">
        <BaseStepperVertical
          v-model="tab"
          :items="sectionLabels"
          :title="$t('system_request__section')"
        />
      </v-col>

      <v-col
        xs="8"
        sm="8"
        md="8"
        lg="8"
        xl="8"
        xxl="8"
        class="scrollable_content border-left"
      >
        <v-row
          dense
          no-gutters
          align="center"
          v-if="system_request.survey.name == 'SickAG'"
        >
          <!-- <v-col
            style="display: flex; align-items: center; justify-content: left"
          >
          </v-col> -->
          <v-col
            style="display: flex; align-items: center; justify-content: right"
            class="pr-2"
          >
            <v-label class="pr-3">
              {{ $t("system_request__advanced_options") }}
            </v-label>

            <v-chip-group
              v-model="system_request.request.mode__sickag_mode"
              selected-class="text-primary"
              mandatory
              column
              filter
              variant="tonal"
              @update:model-value="updateTab()"
            >
              <v-chip value="quick" selected-class="chip-selected">
                {{ $t("system_request__simple_mode") }}
              </v-chip>
              <v-chip value="detailed" selected-class="chip-selected">
                {{ $t("system_request__extended_mode") }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-card-text id="applicationCardText">
          <v-window disabled v-model="tab">
            <v-window-item
              v-for="(section, index) in sectionsVisible"
              :value="index + 1"
            >
              <v-alert
                type="info"
                variant="tonal"
                class="mb-5"
                border="end"
                v-if="section['help_text_' + settings.locale]"
              >
                {{ section["help_text_" + settings.locale] }}
              </v-alert>

              <ApplicationFormSection :section="section" />
            </v-window-item>
          </v-window>
        </v-card-text>

        <v-card-actions class="mt-auto">
          <v-btn color="secondary" @click="tab = tab > 1 ? tab - 1 : 1">{{
            $t("previous")
          }}</v-btn>
          <v-btn
            color="secondary"
            @click="tab = tab < sectionsVisible.length ? tab + 1 : tab"
            >{{ $t("next") }}</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import router from "@/router";
import { ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/stores/settings";
import ProjectActionPrompt from "./ProjectActionPrompt.vue";
import ApplicationFormSection from "./ApplicationFormSection.vue";
import BaseToolbarMenu from "./BaseToolbarMenu.vue";
import { useWindowSize } from "@vueuse/core";
import { useApiSchemaAdapter } from "@/composables/useApiSchemaAdapter";
import useReportGenerator from "@/composables/useReportGenerator";
import { VStepperVertical } from "vuetify/labs/VStepperVertical";
import BaseStepperVertical from "@/components/BaseStepperVertical.vue";

import { useAutosaveStore } from "@/stores/autosave";
const autosave = useAutosaveStore();

const { height } = useWindowSize();
const { mapIfOld } = useApiSchemaAdapter();
const props = defineProps(["projectID", "params"]);
const settings = useSettingsStore();
const project_prompt = ref(null);
const { t, te } = useI18n();
const system_request = useSystemRequestStore();
const { request, solutions, project_active, project, selected } =
  storeToRefs(system_request);
const { downloadApplicationSpecCSV } = useReportGenerator(t, te);

const tab = ref(1);
const currentSection = ref("");

const sectionLabels = computed(() => {
  if (system_request.sections) {
    return system_request.sections
      .filter((section) => checkVisibility(section))
      .map((section) => {
        if (te("system_request_" + section.label)) {
          return t("system_request_" + section.label);
        }
        return section[settings.locale];
      });
  }
  return [];
});

const sectionsVisible = computed(() => {
  if (system_request.sections) {
    return system_request.sections.filter((section) =>
      checkVisibility(section)
    );
  }
  return [];
});

const toolbarMenuActions = computed(() => {
  return [
    {
      title: t("system_request__save_project"),
      icon: "mdi-content-save-outline",
      action: saveProject,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__save_as"),
      icon: "mdi-content-copy",
      action: saveProjectAs,
      ctrl_action: () => {},
      show: () => {
        return project_active.value;
      },
    },
    {
      title: t("system_request__rename_project"),
      icon: "mdi-rename",
      action: renameProject,
      ctrl_action: () => {},
      show: () => {
        return project_active.value;
      },
    },
    {
      title: t("system_request__create_link"),
      icon: "mdi-export-variant",
      action: () => system_request.share_project(false),
      ctrl_action: () => system_request.share_project(true),
      show: () => true,
    },
    {
      title: t("system_request__new_search"),
      icon: "mdi-archive-plus",
      action: newSearch,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__export_project"),
      icon: "mdi-microsoft-excel",
      action: downloadApplicationSpecCSV,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__load_autosave"),
      icon: "mdi-file-undo-outline",
      action: () => { autosave.load(); system_request.get_solutions()},
      ctrl_action: () => {},
      show: () => true,
    },
  ];
});

function updateTab() {
  let newValue = sectionsVisible.value
    .map((section) => {
      if (te("system_request_" + section.label)) {
        return t("system_request_" + section.label);
      }
      return section[settings.locale];
    })
    .indexOf(currentSection.value);
  if (newValue > -1) {
    tab.value = newValue + 1;
  } else {
    tab.value = 1;
  }
}

watch(tab, () => {
  currentSection.value = sectionLabels.value[tab.value - 1];
});

function saveProject() {
  if (project_active.value) {
    system_request.update_project();
  } else {
    system_request
      .create_dialog(project_prompt, system_request.request)
      .then((res) => {
        router.push({
          name: "project",
          params: { projectID: res.id },
        });
      });
  }
}

function saveProjectAs() {
  system_request
    .copy_dialog(project_prompt, system_request.request)
    .then((res) => {
      router.push({ name: "project", params: { projectID: res.id } });
    });
}

function renameProject() {
  system_request
    .edit_dialog(project_prompt, system_request.project)
    .then((res) => {
      system_request.project = res;
    })
    .catch((err) => {
      if (err) {
        notification_center.push_notification(
          t("systems__project_deleted_failed"),
          err,
          NotificationType.Error
        );
      }
    });
}

function newSearch() {
  router.push("/");
  system_request.reset().then(() => system_request.get_solutions());
}

onMounted(() => {
  if (props.params != null) {
    system_request
      .reset()
      .then(() => {
        request.value = mapIfOld(JSON.parse(props.params));
      })
      .then(() => system_request.get_solutions());
  } else if (props.projectID) {
    system_request
      .set_project(props.projectID)
      .then((res) => {
        selected.value = solutions.value.find((e) => {
          return e.id == project.value.favourite;
        });
      })
      .then(() => system_request.get_solutions());
  } else {
    system_request
      .reset()
      // .then(() => {
      //   autosave.load();
      // })
      .then(() => {
        system_request.get_solutions();
      });
  }
});

function aredisjoint(set1, set2) {
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i] == set2[j]) return false;
    }
  }
  return true;
}

function checkVisibility(element) {
  if (element.includeIf == null) {
    return true;
  }

  for (let c in element.includeIf) {
    let condition = element.includeIf[c];
    let selection = request.value[condition.field];
    if (selection == null) {
      return false;
    }

    if (Array.isArray(selection)) {
      if (aredisjoint(condition.values, selection)) {
        return false;
      }
    } else {
      if (!condition.values.includes(selection)) {
        return false;
      }
    }
  }
  return true;
}
</script>

<style scoped>
html,
body {
  overflow: hidden !important;
}
#application {
  display: flex !important;
  flex-direction: column;
}
#applicationCardText {
  flex-grow: 1;
  overflow-y: auto;
}
.scrollable_content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 170px);
}
/* .v-tab {
  font-size: 12px; 
} */
.border-left {
  border-left: 1px solid #ddd;
}
.chip-selected {
  background: #f5f5f5;
  color: black;
}
</style>
